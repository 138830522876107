.button {
  background-color: #ffe81c !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  padding: 12px 0 !important;
  font-size: 18px !important;
}
.button:hover {
  background-color: #fdee30 !important;
}
