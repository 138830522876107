.custom-button {
  font-weight: 500 !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.large-button {
  padding: 7px 70px !important;
  font-size: 14px !important;
}

.small-button {
  padding: 4px 40px !important;
  font-size: 14px !important;
}

.extra-small-button {
  padding: 4px !important;
  min-width: 40px !important;
}

.color-yellow {
  background-color: #fdee30 !important;
  color: #000000 !important;
}

.color-charcoal {
  background-color: #3e3e3e !important;
  color: #ffffff !important;
}

.color-red {
  background-color: #f03030 !important;
  color: #ffffff !important;
}

.color-green {
  background-color: #349e20 !important;
  color: #ffffff !important;
}

.color-pink {
  background-color: #c4c9dc !important;
  color: #3e3e3e !important;
}

.no-margin {
  margin: 0 !important;
}

.margin-4 {
  margin: 4px !important;
}

.go-button {
  margin-bottom: 22px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.padding-7 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.modal-button {
  outline: 1px solid #000000 !important;
  background-color: #ffffff !important;
  /* padding: 6px 60px !important; */
  margin: 0 !important;
}

.margin-x-4 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.margin-y-2 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.outline-yellow {
  outline: 1px solid #fdee30 !important;
}

.action-button {
  width: 120px !important;
  height: 40px !important;
}

.button-container {
  text-wrap: nowrap;
  padding: 4px 20px !important;
}
