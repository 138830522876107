.custom-modal::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
.custom-modal {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.custom-modal::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.custom-modal::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

#swal2-html-container {
  max-height: 600px;
  overflow-y: auto;
}
