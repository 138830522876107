html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
.table-scrollbar::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
.table-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.table-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.table-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.table-container {
  width: 100%;
  overflow: hidden;
}

.color-dodger-blue {
  color: #3096f0 !important;
}
