.body {
  flex-grow: 1 !important;
  padding: 36px 44px !important;
  width: 70vw !important;
}

.appbar {
  z-index: 0 !important;
  padding-right: 42px;
  background-color: #f9f9f9 !important;
  box-shadow: none !important;
}

.toolbar {
  /* padding: 0px !important; */
  padding: 20px 0px !important;

  /* padding-right: 32px !important; */
  justify-content: space-between;
  border-bottom: 0.6px solid #999999 !important;
}

.menu-button {
  color: #3e3e3e;
  transform: scale(1.9);
}

.chevron-color {
  color: #ffffff;
}

.icon-button {
  margin-left: 0px !important;
  margin-right: 15px !important;
}

.hide-scroll::-webkit-scrollbar {
  display: none !important;
}

.hide-scroll {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.sidebar-icon {
  width: 18px;
  height: 18px;
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.icon-open-active {
  background-color: #000000;
}

.icon-closed-active {
  background-color: #fdee30;
}

.icon-open-inactive {
  background-color: #a9b5df;
}

.icon-closed-inactive {
  background-color: #959595;
}

.dashboard-icon {
  mask-image: url("./../assets/images/DashboardIcon.svg");
  --webkit-mask-image: url("./../assets/images/DashboardIcon.svg");
}

.vehicle-icon {
  mask-image: url("./../assets/images/VehicleIcon.svg");
  --webkit-mask-image: url("./../assets/images/VehicleIcon.svg");
}

.auction-icon {
  mask-image: url("./../assets/images/AuctionIcon.svg");
  --webkit-mask-image: url("./../assets/images/AuctionIcon.svg");
}

.arbitration-icon {
  mask-image: url("./../assets/images/ArbitrationIcon.svg");
  --webkit-mask-image: url("./../assets/images/ArbitrationIcon.svg");
}

.groups-icon {
  mask-image: url("./../assets/images/GroupsIcon.svg");
  --webkit-mask-image: url("./../assets/images/GroupsIcon.svg");
}

.user-icon {
  mask-image: url("./../assets/images/UserIcon.svg");
  --webkit-mask-image: url("./../assets/images/UserIcon.svg");
}

.transporter-icon {
  mask-image: url("./../assets/images/TransporterIcon.svg");
  --webkit-mask-image: url("./../assets/images/TransporterIcon.svg");
}

.audit-trail-icon {
  mask-image: url("./../assets/images/AuditTrailIcon.svg");
  --webkit-mask-image: url("./../assets/images/AuditTrailIcon.svg");
}

.setting-icon {
  mask-image: url("./../assets/images/SettingIcon.svg");
  --webkit-mask-image: url("./../assets/images/SettingIcon.svg");
}

.logout-icon {
  mask-image: url("./../assets/images/LogoutIcon.svg");
  --webkit-mask-image: url("./../assets/images/LogoutIcon.svg");
}

.stakeholder-icon {
  mask-image: url("./../assets/images/StakeholderIcon.svg");
  --webkit-mask-image: url("./../assets/images/StakeholderIcon.svg");
}
