.access-table-container-main {
  max-height: calc(100vh - 170px);
}

.access-table-container {
  border: 0px !important;
  outline: 0px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.access-table-parent {
  border-collapse: separate !important;
  border-spacing: 0px 15px !important;
  padding: 5px !important;
  background-color: #f9f9f9 !important;
}

.access-parent-row {
  outline: 0px !important;
  border: 0px !important;
}

.access-table-row {
  border-radius: 0px !important;
  outline: 0px !important;
}

.access-table-cell {
  border: 0px !important;
  border-radius: 0px !important;
}
