.small-select {
  height: 35px;
}
.standard-select {
  color: #3096f0 !important;
}

.standard-select::before {
  border-bottom: none !important;
}

.standard-select > .MuiSelect-standard {
  padding-bottom: 0px !important;
}

.standard-select > .MuiSelect-icon {
  color: #3096f0 !important;
}

@media screen and (max-width: 768px) {
  .standard-select {
    font-size: 14px !important;
  }
}
