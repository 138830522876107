.audit-table-container {
  box-shadow: none !important;
  background-color: rgba(245, 244, 244, 0.49) !important;
}

.audit-table {
  border-spacing: 0 15px !important;
  border-collapse: separate !important;
  background-color: rgba(245, 244, 244, 0.49) !important;
  width: calc(100% - 2px) !important;
  margin: 0 1px;
}

.audit-table-head {
  width: "100%";
}
.audit-table-head > th {
  box-shadow: none !important;
  border: none !important;
}

.audit-table-head > th:last-child {
  border-right: 1px solid #e5e7eb !important;
  border-radius: 0 10px 10px 0;
}

.audit-table-head > th:first-child {
  border-left: 1px solid #e5e7eb !important;
  border-radius: 10px 0 0 10px;
}

.MuiTableBody-root > tr {
  background-color: #ffffff !important;
  border-radius: 10px;
  outline: 1px solid #e5e7eb;
  overflow: hidden !important;
}

.dull-row > td {
  background-color: #e5e7eb !important;
}

.audit-table-row > td {
  font-size: 15px !important;
  border-bottom: none !important;
}

.audit-table-row > td:last-child {
  border-radius: 0 10px 10px 0;
}

.audit-table-row > td:first-child {
  border-radius: 10px 0px 0px 10px;
}

.vertical-text-top {
  vertical-align: text-bottom !important;
}
