.glassmorphism {
  background: rgba(82, 73, 73, 0.49) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.8px);
  -webkit-backdrop-filter: blur(13.8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.background-white {
  background: rgba(245, 244, 244, 0.49) !important;
}

.no-border-radius {
  border-radius: 0px !important;
}

.cerulean-blue {
  color: #3096f0;
}

.desaturated-blue {
  color: #a0c7f8;
}

.box-shadow {
  box-shadow: 5px 5px 20px #0000000d;
}

*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.react-datepicker__input-container > button {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}

.react-datepicker__input-container > button:hover {
  border-color: rgba(0, 0, 0, 1);
}
